@import '_constants.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_fonts.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_mixins.sass'
@import '_variables.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/components/__components.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/layout/__layout.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/pages/__pages.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/main.sass'

.header__row .logo
    height: 115px

/* For Mobile View */
@media screen and (min-width: $min-screen-resolution-width) and (max-width: $max-screen-resolution-width)
    .welcome div#additionalZone1Image img, .welcome div#additionalZone2Image img
        width: 100%
        max-height: auto !important
